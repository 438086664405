





















































































































import { Component, Vue, Watch } from "vue-property-decorator";
import BaseInfo from "./components/BaseInfo.vue";
import Parts from "./components/Parts.vue";
import PeiTao from "./components/PeiTao.vue";
import ProcessInfo from "./components/ProcessInfo.vue";
import Energy from "./components/Energy.vue";
import Sop from "./components/Sop.vue";
import ExperimentBook from "./components/ExperimentBook.vue";
import InstrumentOrder from "./components/InstrumentOrder.vue";
import ElectronicScreen from "./components/ElectronicScreen.vue";
import LifeCycle from "./components/LifeCycle.vue";
import { IInstrument, instrumentApi } from '@/api/instrument'
import { enterpriseApi } from "@/api/enterprise";
import { getQueryParamByKey, addOrUpdateQuery } from '@/utils';
import InstrumentFile from '@/views/instrument/components/InstrumentFile.vue'

@Component({
  components: {
    BaseInfo,
    Parts,
    PeiTao,
    ProcessInfo,
    Energy,
    Sop,
    ExperimentBook,
    InstrumentOrder,
    ElectronicScreen,
    LifeCycle,
    InstrumentFile
  },
})
export default class Detail extends Vue {
  activeName: string = "first";
  detail: any = {};
  instrumentFields: any[] = [];
  visible: boolean = false;
  color: string = "红色";

  parentIns = {} as any

  @Watch("activeName")
  handleActiveName(v: any) {
    addOrUpdateQuery("activeName", v);
  }

  getParentIns() {
    if (!this.detail.parentId) {
      return
    }
    instrumentApi.getInstrumentDetail(this.detail.parentId).then((ins) => {
      this.parentIns = ins
    }).catch((e) => {
      this.$message.warning(`获取父设备失败, ${e}`)
    })
  }

  handleClick() {}
  back() {
    this.$router.push("/ins/ins");
  }
  async getDetail() {
    this.detail = await instrumentApi.getInstrumentDetail(
      Number(this.$route.params.id)
    );
    this.getParentIns()
    await this.getFields(this.detail.enterpriseId);
  }
  async created() {
    await this.getDetail();
    this.activeName = getQueryParamByKey("activeName") || "first";
    console.log(this.instrumentFields)
  }
  lightAction() {
    const colorMap: any = {
      红色: "red",
      蓝色: "blue",
      绿色: "green",
    };
    instrumentApi
      .light(this.detail.id, colorMap[this.color])
      .then(() => {
        this.$message.success("亮灯成功");
        this.visible = false;
      })
      .catch((err) => {
        this.$message.error(err);
      });
  }
  light() {
    this.visible = true;
  }
  unbind() {
    this.$confirm(`是否继续解绑?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      instrumentApi.unbind(this.detail.id).then(() => {
        // @ts-ignore
        this.$message.success("解绑成功").catch((err) => {
          this.$message.error(err);
        });
      });
    });
  }
  private async getFields(enterpriseId: number) {
    const [
      { value: sortRes = [] },
      { value: res = [] },
      { value: extraFields = [] },
    ]: any = await Promise.allSettled([
      enterpriseApi.getFieldsSort({
        enterpriseId,
      }),
      enterpriseApi.getFields({
        enterpriseId,
      }),
      instrumentApi.getInstrumentFields(),
    ]);

    const fields: any = [
      ...(sortRes?.length ? sortRes : []),
      ...res
        ?.reduce((s: any[], c: any) => {
          if (c instanceof Array) {
            return [...s, ...c];
          }
          return [...s, c];
        }, [])
        .filter(
          (child: any) =>
            !(sortRes?.length ? sortRes : [])?.find(
              (item: any) => child.key === item.key
            )
        ),
    ];
    this.instrumentFields = fields
      ?.filter(({ key }: any) => key !== "extend_info")
      .map((child: any) => {
        const field = extraFields.find((item: any) => item.id == child.key);
        if (field) {
          return {
            ...child,
            ...field,
          };
        }
        return child;
      });
  }
}
