






import { Component, Vue, Prop } from "vue-property-decorator";
import Order from "../order.vue";

@Component({ components: { Order } })
export default class ProcessInfo extends Vue {
  @Prop() data: any;
}
