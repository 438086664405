




























































import { Component, Vue, Prop } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";
import { groupApi } from "@/api/group";
import { enterpriseApi } from "@/api/enterprise";
import { instrumentApi } from "@/api/instrument";
import { userApi } from "@/api/user";
import { convertRes2Blob } from "@/utils/download";

@Component
export default class ExperimentBook extends Vue {
  @Prop() data: { id: any; } | undefined;
  private params = { page: 1, perPage: 15 };
  private list = {};
  private loading = false;
  private visible = false;
  private createModifyLoad = false;
  private title = "新增仪器组";
  private openType = "create";
  private openLoading = false;

  private departments = {};
  private enterprises = {};
  private users = {};
  condition: any = {};

  get isSuperAdmin(): boolean {
    return UserModule.isSuperAdmin;
  }

  get formatterRemark() {
    // @ts-ignore
    return (remark) => {
      return Object.keys(remark)
        .map((key) => `${key}:${remark[key]}`)
        .join(";");
    };
  }
  get formatterStatus() {
    // @ts-ignore
    return (status) => {
      return status === 1 ? "实验中" : "实验完成";
    };
  }
  get extraConfig() {
    return [
      {
        key: "startTimeFrom",
        label: "开始起",
        type: "datetime",
        placeholder: "请选择开始结束时间",
      },
      {
        key: "startTimeTo",
        label: "至",
        type: "datetime",
        placeholder: "请选择开始结束时间",
      },
      {
        key: "endTimeFrom",
        label: "结束起",
        type: "datetime",
        placeholder: "请选择开始结束时间",
      },
      {
        key: "endTimeTo",
        label: "至",
        type: "datetime",
        placeholder: "请选择开始结束时间",
      },
    ];
  }

  onRefresh(condition: any) {
    this.condition = condition;
    this.getList();
  }

  private async getList() {
    this.loading = true;
    try {
      const res = await instrumentApi.getTentatives({
        // @ts-ignore
        id: this.data.id,
        ...this.params,
        ...this.condition,
      });
      this.list = res;
    } catch (error) {}
    this.loading = false;
  }
  async onExport() {
    try {
      const response = await instrumentApi.getTentatives({
        // @ts-ignore
        id: this.data.id,
        ...this.params,
        ...this.condition,
        isExport: 1,
      });
      if (!response) true;
      convertRes2Blob(response);
    } catch (error) {
      this.$message.error(error);
    }
  }
  private created() {
    // this.getList();
  }
  handleSizeChange(size: number) {
    this.params.perPage = size;
    this.getList();
  }

  handlePageChange(page: number) {
    this.params.page = page;
    this.getList();
  }
}
