
























































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";
import { IInstrumentParams, instrumentApi } from "@/api/instrument";
import { departmentApi } from "@/api/department";
import { enterpriseApi, IGetEnterprisesParam } from "@/api/enterprise";
import { IUserParams, userApi } from "@/api/user";
import { groupApi } from "@/api/group";
import PERMISSION from "@/constants/permission";
import { convertRes2Blob, downloadExcel } from "@/utils/download";
import { downloadZip, onPreviewQrcode } from "@/utils/qrcode";
import GenerateForm from "@/components/VueFormMaking/components/GenerateForm.vue";
import InstrumentCreateForm from "@/components/InstrumentCreateForm.vue";
import { orderApi } from "@/api/order";
import { processApis } from "@/api/process";

const batchSettingMap: any = {
  custodianId: "保管人",
  deviceGroupId: "仪器组",
};
@Component({
  components: {
    GenerateForm,
    InstrumentCreateForm,
  },
})
export default class Instrument extends Vue {
  @Prop() type: string | undefined;
  @Prop() status: number | undefined;
  @Prop() processName: number | undefined;
  createOrderLoading = false;
  orderVisible = false;
  creatLoading = false;
  generateForm = {
    list: [],
    config: [],
  };
  widgetModels = {};
  private params = {
    page: 1,
    perPage: 15,
  };
  private instruments = {};
  private loading = false;
  private visible = false;
  private createModifyLoad = false;
  private title = "新增仪器";
  private openType = "create";
  private instrumentFields = [];
  private openLoading = false;
  currentProcess = {};
  importLoading = false;
  exportLoading = false;
  exportVisible = false;
  condition: any = {};
  batchSettingVisible = false;
  batchSettingTitle = "批量设置管理员";
  batchType = "managerId";
  batchSettingList = [];
  batchSettingId = null;
  private createModifyParams: any = {
    enterpriseId: 1,
    departmentId: null,
    name: "",
    code: "",
    extraFields: {},
  };
  private departments = {};
  downloadLoading = false;
  private enterprises = {};
  users = {};
  groups = {};
  exportFields = [];
  private rules = {
    name: [
      {
        required: true,
        message: "请输入名称",
        trigger: "blur",
      },
    ],
    code: [
      {
        required: true,
        message: "请输入设备编号",
        trigger: "blur",
      },
    ],
    departmentId: [
      {
        required: true,
        message: "请选择",
        trigger: "blur",
      },
    ],
  };
  private needApprovalOptions: any = {
    0: "不需要",
    1: "需要",
  };

  @Watch("condition", { deep: true })
  handleCondition(v: any) {
    // console.log(v,'v')
  }

  onPreviewQrcode(row: any) {
    onPreviewQrcode(
      this,
      `https://www.zjiims.com/qr/ins?id=${row.id}`,
      row.name,
      row.code
    );
  }

  async createOrder(row: any) {
    this.createOrderLoading = true;
    try {
      // @ts-ignore
      const process: any = await processApis.getProcess(this.processName);
      this.currentProcess = process;
      const { formId } = process;
      const form: any = await processApis.getForm(formId);
      // const res
      const { list, config } = form.structure;
      const startNode = process?.structure?.nodes?.find(
        // @ts-ignore
        (node) => node.clazz === "start"
      );
      this.generateForm = {
        config,
        // @ts-ignore
        list: list.filter((item) =>
          startNode?.visiableFields?.split(",")?.includes(item.model)
        ),
      };
      this.widgetModels = {
        instrument: this.type === "verity" ? [row.id] : row.id,
      };
      this.orderVisible = true;
    } catch (error) {
      console.log(error, "error");
      this.$message.error(error);
    }
    this.createOrderLoading = false;
  }

  async doCreateOrder() {
    this.creatLoading = true;
    try {
      // @ts-ignore
      const formData = await this.$refs.generateForm.getData();
      await orderApi.createOrder({
        // @ts-ignore
        processId: this.currentProcess?.id,
        formData,
      });
      this.orderVisible = false;
      this.$message.success("发起成功");
      this.onRefresh(this.condition)
    } catch (error) {
      this.$message.error(error);
    }
    this.creatLoading = false;
  }

  get formatterGroup() {
    // @ts-ignore
    return (row) =>
      // @ts-ignore
      this.groups?.items?.find((group) => group.id === row.deviceGroupId)?.name;
  }

  get showField() {
    const { user, isSuperAdmin }: any = UserModule;
    const isAdmin = user.manage_enterprise_ids?.includes(user.enterprise_id);
    return (key: string) => {
      return this.visibleColumns.includes(key) || isAdmin || isSuperAdmin;
    };
  }

  get batchSettingOptions(): any {
    if (this.batchType === "deviceGroupId") {
      // @ts-ignore
      return this.groups.items;
    }
    // @ts-ignore
    return this.users.items;
  }

  async onBatchSetting() {
    if (!this.batchSettingList?.length) return;
    if (!this.batchSettingId) {
      return this.$message.error("请先选择" + batchSettingMap[this.batchType]);
    }
    try {
      const params = {
        // @ts-ignore
        insIds: this.batchSettingList?.map((child) => child.id),
        [this.batchType]: this.batchSettingId,
      };
      await instrumentApi.multipleSetting(params);
      this.$message.success(`设置${batchSettingMap[this.batchType]}成功`);
      this.getInstruments();
      this.batchSettingVisible = false;
    } catch (error) {
      this.$message.error(error);
    }
  }

  get isSuperAdmin(): boolean {
    return UserModule.isSuperAdmin;
  }

  get visibleColumns() {
    // @ts-ignore
    return UserModule.user.visible_columns;
  }

  get PERMISSION() {
    return PERMISSION;
  }

  handleChangeTime(_: any) {
    // console.log(v, "handleChangeTime");
  }

  get extraConfig() {
    return [
      {
        key: "enterpriseId",
        label: "企业",
        type: "select",
        // @ts-ignore
        options: this.enterprises?.items?.map((child) => ({
          value: child.id,
          label: child.name,
        })),
        placeholder: "请选择企业",
        hidden: !this.isSuperAdmin,
      },
      {
        key: "departmentId",
        label: "科室",
        type: "select",
        // @ts-ignore
        options: this.departments?.items?.map((child) => ({
          value: child.id,
          label: child.name,
        })),
        placeholder: "请选择科室",
        filterMethod: (q: string) => this.getDepartments(q),
      },
      {
        key: "brand",
        label: "品牌",
        type: "input",
        placeholder: "请输入品牌",
      },
      {
        key: "status",
        label: "状态",
        type: "select",
        options: this.statusOptions,
        hidden: this.type,
        placeholder: "请选择状态",
      },
      // {
      //   key: "managerId",
      //   label: "管理人",
      //   type: "select",
      //   options: this.users?.items?.map((child) => ({
      //     value: child.id,
      //     label: child.name,
      //   })),
      //   placeholder: "请选择管理人",
      //   filterMethod:(q:string)=>this.getUsers(q),
      // },
      {
        key: "custodianId",
        label: "保管人",
        type: "select",
        // @ts-ignore
        options: this.users?.items?.map((child) => ({
          value: child.id,
          label: child.name,
        })),
        placeholder: "请选择保管人",
        filterMethod: (q: string) => this.getUsers(q),
      },
      {
        key: "deviceGroupId",
        label: "仪器组",
        type: "select",
        // @ts-ignore
        options: this.groups?.items?.map((child) => ({
          value: child.id,
          label: child.name,
        })),
        placeholder: "请选择仪器组",
        filterMethod: (q: string) => this.getGroups(q),
      },
      {
        key: "bindEs",
        label: "绑定价签",
        type: "select",
        options: [
          {
            value: null,
            label: "全部",
          },
          {
            value: 0,
            label: "未绑定",
          },
          {
            value: 1,
            label: "已绑定",
          },
        ],
        placeholder: "是否绑定价签",
      },
      {
        key: "insType",
        label: "设备类型",
        type: "select",
        options: [
          {
            value: null,
            label: "全部",
          },
          {
            value: 0,
            label: "配套",
          },
          {
            value: 1,
            label: "非配套",
          },
        ],

        placeholder: "全部/配套/非配套",
      },
    ];
  }

  get formatterLabel() {
    // @ts-ignore
    return (row) => {
      // @ts-ignore
      if (!this.config.noUseStatus) {
        return "";
      }
      const statusLabel = this.statusOptions?.find(
        // @ts-ignore
        ({ key }) => key === row.status
      )?.label;
      // @ts-ignore
      if (this.config?.noUseStatus?.includes(row.status)) {
        return `停用(${statusLabel})`;
      }
      // @ts-ignore
      return `${this.config?.in_tentative?.[row.inTentative]}(${statusLabel}）`;
    };
  }

  get statusIcon() {
    // @ts-ignore
    return (row) => {
      // @ts-ignore
      if (!this.config.noUseStatus) {
        return -2;
      }
      // @ts-ignore
      if (this.config?.noUseStatus?.includes(row.status)) {
        return -1;
      }
      if (row.inTentative) return 1;
      return 0;
    };
  }

  get statusOptions() {
    return (
      // @ts-ignore
      this.config?.instrumentStatus?.map((obj) => {
        const key = Number(Object.keys(obj)[0]);
        return {
          key,
          value: key,
          label: obj[key],
        };
      }) || []
    );
  }

  get batchSettingDisabled() {
    return !this.batchSettingList?.length;
  }

  get config() {
    return UserModule.config;
  }

  handleSelectionChange(row: any) {
    this.batchSettingList = row;
  }

  async handleCommand(command: string) {
    if (command === "batchDownloadQrcode") {
      this.downloadLoading = true;
      try {
        await downloadZip(
          this.batchSettingList?.map((row) => ({
            // @ts-ignore
            content: `https://www.zjiims.com/qr/ins?id=${row.id}`,
            // @ts-ignore
            title: row.name,
            // @ts-ignore
            subTitle: row.code,
          })),
          "IIMS仪器二维码批量导出"
        );
      } catch (error) {}
      this.downloadLoading = false;
      return;
    }
    this.batchSettingVisible = true;
    this.batchType = command;
    this.batchSettingId = null;
    if (command === "custodianId") {
      this.batchSettingTitle = "批量设置保管人";
    } else {
      this.batchSettingTitle = "批量设置仪器组";
    }
  }

  onRefresh(condition: any) {
    this.condition = condition;
    this.params.page = 1;
    this.getInstruments();
  }

  async onOpen(row: any = {}, type = "create") {
    if (type === "preview") {
      return this.$router.push(`/ins/detail/${row.id}`);
    }
    this.openType = type;
    this.openLoading = true;
    try {
      if (row?.id) {
        const data = await this.getInstrumentDetail(row.id);
        this.createModifyParams = {
          ...data,
          // need_approval: data.needApproval,
          // @ts-ignore
          extraFields: data.extra_fields,
          // @ts-ignore
          deviceGroupId: data.deviceGroupId || null,
        };
        this.title = "编辑仪器";
      } else {
        this.createModifyParams = {
          extraFields: {},
        };
        this.title = "新增仪器";
      }
    } catch (error) {}
    this.openLoading = false;
    this.visible = true;
  }

  // @ts-ignore
  private async onDelete(row) {
    this.$confirm(`是否继续删除仪器 ${row.name}?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        instrumentApi.deleteInstrument(row.id).then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.getInstruments();
        });
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
  }

  private getGroups(name: string) {
    groupApi
      .getGroups({
        name,
        // @ts-ignore
        page: 1,
        perPage: 99999,
        baseOnly: 1,
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          : // @ts-ignore
            UserModule?.user?.enterprise?.id,
      })
      .then((res) => {
        this.groups = res;
      });
  }

  private async getInstruments() {
    this.loading = true;
    try {
      this.instruments = await instrumentApi.getInstruments({
        status: this.type ? this.status : undefined,
        ...this.params,
        ...this.condition,
      });
    } catch (error) {}
    this.loading = false;
  }

  onExport() {
    this.exportVisible = true;
    this.exportFields = [];
  }

  get exportFieldsOptions() {
    const { user, isSuperAdmin }: any = UserModule;
    const isAdmin = user.manage_enterprise_ids?.includes(user.enterprise_id);
    return this.instrumentFields
      .filter(
        (field: any) =>
          this.visibleColumns.includes(String(field.key)) ||
          isAdmin ||
          isSuperAdmin
      )
      .map((field: any) => ({
        ...field,
        label: field.value,
      }));
  }

  formatterContent(key: string, type: string, row: any) {
    let value =
      row?.[
        String(key)?.replace(/\_(\w)/g, function (all, letter) {
          return letter.toUpperCase();
        })
      ];
    if (key === "status") {
      value = this.formatterLabel(row);
    }
    if (key === "need_approval") {
      value = this.needApprovalOptions[row?.[key] as number];
    }
    if (key === "custodian") {
      value = row?.custodian_name;
    }
    if (key === "manager_id") {
      value = row?.manager_name;
    }
    if (key === "department_id" || key == "departmentId") {
      // @ts-ignore
      value = this.departments?.items?.find(
        (department: any) => department.id === row?.departmentId
      )?.name;
    }
    if (key === "device_group_id") {
      // @ts-ignore
      value = this.groups?.items?.find(
        (group: any) => group.id === row?.deviceGroupId
      )?.name;
    }
    if (key === "enterprise_id") {
      // @ts-ignore
      value = this.enterprises?.items?.find(
        (enterprise: any) => enterprise.id === row?.enterpriseId
      )?.name;
    }
    if (type) {
      value = row.extra_fields?.[key];
    }
    return value || "/";
  }

  async onExportInstrument() {
    if (!this.exportFields?.length) {
      return this.$message.error("请选择导出字段");
    }
    this.exportLoading = true;
    const con = this.condition;
    con.isExport = 1;
    con.page = 1;
    con.perPage = 9999999;

    try {
      const res = await instrumentApi.getInstruments(con as any);
      // @ts-ignore
      const data = res?.data?.data.items?.map((child) => {
        const row = {};
        this.exportFields.forEach((key) => {
          const field = this.exportFieldsOptions.find(
            (field) => field.key === key
          );
          // @ts-ignore
          row[field.label] = this.formatterContent(key, field.type, child);
        });
        return row;
      });
      downloadExcel({
        data,
        name: `IIMS仪器导出文件`,
      });
      this.exportVisible = false;
    } catch (error) {
      this.$message.error(error);
    }
    this.exportLoading = false;
    // try {
    //   const response = await instrumentApi.getInstruments({
    //     ...this.params,
    //     ...this.condition,
    //     isExport: 1,
    //   });
    //   if (!response) true;
    //   convertRes2Blob(response);
    // } catch (error) {
    //   this.$message.error(error);
    // }
  }

  // @ts-ignore
  private async getInstrumentDetail(id) {
    return await instrumentApi.getInstrumentDetail(id);
  }

  // private getInstrumentField() {
  //   instrumentApi.getInstrumentFields().then((res) => {
  //     this.instrumentFields = res;
  //   });
  // }
  private async getFields() {
    const [
      { value: sortRes = [] },
      { value: res = [] },
      { value: extraFields = [] },
    ]: any = await Promise.allSettled([
      enterpriseApi.getFieldsSort({
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          : // @ts-ignore
            UserModule?.user?.enterprise?.id,
      }),
      enterpriseApi.getFields({
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          : // @ts-ignore
            UserModule?.user?.enterprise?.id,
      }),
      instrumentApi.getInstrumentFields(),
    ]);

    const fields: any = [
      ...(sortRes?.length ? sortRes : []),
      ...res
        ?.reduce((s: any[], c: any) => {
          if (c instanceof Array) {
            return [...s, ...c];
          }
          return [...s, c];
        }, [])
        .filter(
          (child: any) =>
            !(sortRes?.length ? sortRes : [])?.find(
              (item: any) => child.key === item.key
            )
        ),
    ];
    this.instrumentFields = fields
      ?.filter(({ key }: any) => key !== "extend_info")
      .map((child: any) => {
        const field = extraFields.find((item: any) => item.id == child.key);
        if (field) {
          return {
            ...child,
            ...field,
          };
        }
        return child;
      });
  }

  private getDepartments(query: string) {
    departmentApi
      .getDepartments({
        name: query,
        page: 1,
        perPage: 99999,
        baseOnly: 1,
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          : // @ts-ignore
            UserModule?.user?.enterprise?.id,
      })
      .then((data) => {
        this.departments = data;
      })
      .catch((e) => {
        this.$message.warning(`获取科室列表失败, ${e}`);
      });
  }

  private getEnterprises(query: string) {
    // @ts-ignore
    enterpriseApi
      .getEnterprises({
        name: query,
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          : // @ts-ignore
            UserModule?.user?.enterprise?.id,
        baseOnly: 1
      } as IGetEnterprisesParam)
      .then((data) => {
        this.enterprises = data;
      })
      .catch((e) => {
        this.$message.warning(`获取企业列表失败, ${e}`);
      });
  }

  private getUsers(query: string) {
    // @ts-ignore
    userApi
      .getUsers({
        name: query,
        page: 1,
        perPage: 99999,
        baseOnly: 1,
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          : // @ts-ignore
            UserModule?.user?.enterprise?.id,
      } as IUserParams)
      .then((data) => {
        this.users = data;
      })
      .catch((e) => {
        this.$message.warning(`获取用户列表失败, ${e}`);
      });
  }

  private doAction() {
    this.createModifyLoad = true;
    instrumentApi
      .createInstrument(this.createModifyParams)
      .then(() => {
        this.$message.success(this.title);
        this.visible = false;
        this.getInstruments();
      })
      .catch((e) => {
        this.$message.warning(`${this.title}, ${e}`);
      })
      .finally(() => {
        this.createModifyLoad = false;
      });
  }

  private created() {
    this.getFields();
    this.getEnterprises("");
    this.getDepartments("");
    // this.getInstruments();
    this.getUsers("");
    // @ts-ignore
    this.getGroups();
  }

  handleSizeChange(size: number) {
    this.params.perPage = size;
    this.getInstruments();
  }

  handlePageChange(page: number) {
    this.params.page = page;
    this.getInstruments();
  }

  handlePreview(_: any) {}

  handleRemove(_: any, __: any) {}

  async handleChange(file: any, _: any) {
    this.importLoading = true;
    // const [excel] = fileList;
    const excel = file;
    if (!excel) return;
    try {
      const { data } = await instrumentApi.importInstruments(excel.raw);
      if (data?.success) {
        this.$message.success("导入成功");
        this.getInstruments();
      } else {
        this.$message.error(data.message);
      }
    } catch (error) {
      this.$message.error(error);
    }
    this.importLoading = false;
  }

  async onDownloadTemplate() {
    try {
      const response = await instrumentApi.downloadTemplate();
      convertRes2Blob(response, "IIMS仪器导入模版.xlsx");
    } catch (e) {
      this.$message.warning(e.toString());
    }
  }
}
