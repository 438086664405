















import { Component, Vue, Prop } from "vue-property-decorator";
import { orderApi } from "@/api/order";

@Component
export default class Record extends Vue {
  @Prop() data: { id: any; } | undefined;
  loading = false;
  orders: any = {};
  get timeLine() {
    return this.orders?.items;
  }
  private async getOrders() {
    this.loading = true;
    try {
      const res = await orderApi.getOrders({
        page: 1,
        perPage: 9999,
        // @ts-ignore
        instrumentId: this.data.id,
        // @ts-ignore
        state: 1,
      });
      this.orders = res;
    } catch (error) {}
    this.loading = false;
  }
  created() {
    this.getOrders();
  }
}
