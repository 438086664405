





































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  IUserDetail,
  IUserParams,
  ICreateUserParams,
  IModifyUserParams,
  userApi,
  IUsers,
} from "@/api/user";
import { IRoleParams, IRoles, RoleAddUserParams, roleApi } from "@/api/role";
import {
  departmentApi,
  IDepartmentParams,
  IDepartments,
} from "@/api/department";
import {
  enterpriseApi,
  IEnterprises,
  IGetEnterprisesParam,
} from "@/api/enterprise";
import { UserModule } from "@/store/modules/user";
import PERMISSION from "@/constants/permission";

@Component
export default class User extends Vue {
  load = false;
  params = { page: 1, perPage: 15 } as IUserParams;
  users = {} as IUsers;
  roles = {} as IRoles;
  statuss = [
    { value: 1, label: "正常" },
    { value: 0, label: "锁定" },
    { value: -1, label: "删除" },
  ];

  selectRoles: Array<number> = [];
  departments = {} as IDepartments;
  enterprises = {} as IEnterprises;
  visible = false;
  addRolevisible = false;
  roleUservisible = true;
  title = "";
  createModifyParams = {} as ICreateUserParams | IModifyUserParams;
  createModifyLoad = false;
  roleAddUserParams = {} as RoleAddUserParams;
  onChange!: Function;
  condition: any = {};

  get formatterEnterprise() {
    // @ts-ignore
    return (v) => v?.enterpriseName || "/";
  }
  get formatterDepartments() {
    // @ts-ignore
    return (v) => v?.map((child) => child.departmentName)?.join("、") || "/";
  }
  get extraConfig() {
    return [
      {
        key: "telephone",
        label: "电话",
        type: "input",
        placeholder: "请输入手机号",
      },
      {
        key: "roleId",
        label: "角色",
        type: "select",
        options: this.roles?.items?.map((child) => ({
          value: child.id,
          label: child.name,
        })),
        placeholder: "请选择角色",
      },
      {
        key: "status",
        label: "状态",
        type: "select",
        options: this.statuss,
        placeholder: "请选择角色",
      },
      {
        key: "enterpriseId",
        label: "企业",
        type: "select",
        options: this.enterprises?.items?.map((child) => ({
          value: child.id,
          label: child.name,
        })),
        placeholder: "请选择企业",
        hidden: !this.isSuperAdmin,
      },
      {
        key: "departmentId",
        label: "科室",
        type: "select",
        options: this.departments?.items?.map((child) => ({
          value: child.id,
          label: child.name,
        })),
        placeholder: "请选择科室",
      },
    ];
  }
  rules = {
    name: [
      { required: true, message: "请输入用户名称", trigger: "blur" },
      { min: 2, max: 128, message: "长度在 2 到 128 个字符", trigger: "blur" },
    ],
    status: [{ required: true, message: "请输入地址", trigger: "blur" }],
    telephone: [
      { required: true, message: "请输入电话", trigger: "blur" },
      { min: 11, max: 11, message: "请输入正确的电话号码", trigger: "blur" },
    ],
  };

  roleRules = {
    role: [{ required: true, message: "请选择要授权的角色", trigger: "blur" }],
  };

  mounted() {
    // this.getUsers();
    this.getRoles("");
    this.getDepartments("");
    if (this.isSuperAdmin) {
      this.getEnterprises("");
    } else {
      // @ts-ignore
      this.params.enterpriseId = UserModule.user.enterpriseId;
    }
  }

  get isSuperAdmin(): boolean {
    return UserModule.isSuperAdmin;
  }

  get PERMISSION() {
    return PERMISSION;
  }

  onRefresh(condition: any) {
    this.condition = condition;
    this.getUsers();
  }
  getUsers() {
    this.load = true;
    if (!this.isSuperAdmin) {
      // @ts-ignore
      this.params.enterpriseId = UserModule?.user?.enterprise?.id;
    }
    userApi
      .getUsers({ ...this.params, ...this.condition })
      .then((data) => {
        this.users = data;
      })
      .catch((e) => {
        this.$message.warning(`获取科室失败, ${e}`);
      })
      .finally(() => {
        this.load = false;
      });
  }

  handleSizeChange(size: number) {
    this.params.perPage = size;
    this.getUsers();
  }

  handlePageChange(page: number) {
    this.params.page = page;
    this.getUsers();
  }

  handleSelectionChange(users: Array<IUserDetail>) {
    const chooseUserIds = [];
    for (const _user of users) {
      chooseUserIds.push(_user.id);
    }
    this.roleAddUserParams.userIds = chooseUserIds;
    if (this.roleAddUserParams.userIds.length > 0) {
      this.roleUservisible = false;
    } else {
      this.roleUservisible = true;
    }
  }

  createUser() {
    this.title = "添加用户";
    this.createModifyParams = {} as ICreateUserParams;
    this.visible = true;
    this.$forceUpdate();
  }

  createRoleUserRel() {
    this.title = "授予角色";
    this.addRolevisible = true;
    this.$forceUpdate();
  }

  addOneUserRole(row: IUserDetail) {
    this.title = `授予用户${row.name}角色`;
    this.addRolevisible = true;
    const roleList = [];
    if (row.roles && row.roles.length > 0) {
      for (const role of row.roles) {
        roleList.push(role.id);
      }
    }
    this.roleAddUserParams = {
      userIds: [row.id],
      roleIds: roleList,
      isSingleUser: true,
    };
    this.$forceUpdate();
  }

  modifyUser(row: IUserDetail) {
    this.title = `编辑${row.name}用户信息`;
    const roleIdList: Array<number> = [];
    if (row.roles) {
      for (const _role of row.roles) {
        roleIdList.push(_role.id);
      }
    }
    this.createModifyParams = {
      id: row.id,
      name: row.name,
      telephone: row.telephone,
      status: row.status,
      // @ts-ignore
      enterpriseId: row.enterprise?.enterpriseId,
      departmentIds: row.departments?.map(({ departmentId }) => departmentId),
      roleIds: roleIdList,
    } as IModifyUserParams;
    // this.enterprises = this.enterprises?.items?.find(({id})=>id===row.enterpriseId) ? this.enterprises:  {items:[{id:row.enterpriseId,name:row.enterprise.name},...(this.enterprises?.items || [])]} as IEnterprises
    this.selectRoles = this.getRoleIds(row.roles);
    this.visible = true;
    this.$forceUpdate();
  }

  resetUserPassword(row: any) {
    this.$confirm(`是否继续重置用户 ${row.name} 的密码?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        userApi.resetUserPassword(row?.id).then(() => {
          this.$message({
            type: "success",
            message: `重置成功! 重置后密码为：${row.telephone.slice(row.telephone.length - 4, row.telephone.length)}`,
          });
          this.getUsers();
        });
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "已取消重置",
        });
      });
  }
  lockUser(row: any) {
    const title = row.status === 1 ? "锁定" : "解锁";
    this.$confirm(`是否继续${title}用户 ${row.name}?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        this.createModifyParams = {
          id: row.id,
          name: row.name,
          telephone: row.telephone,
          status: row.status === 1 ? 0 : 1,
          enterpriseId: row.enterprise?.enterpriseId,
          departmentIds: row.departments?.map(
            // @ts-ignore
            ({ departmentId }) => departmentId
          ),
          // @ts-ignore
          roleIds: row.roles?.map(({ id }) => id),
        } as IModifyUserParams;
        // @ts-ignore
        userApi.modifyUser(this.createModifyParams).then(() => {
          this.$message({
            type: "success",
            message: `${title}成功!`,
          });
          this.getUsers();
        });
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: `已取消${title}`,
        });
      });
  }

  deleteUser(row: IUserDetail) {
    // this.$confirm(`是否继续删除用户 ${row.name}?`, "提示", {
    this.$confirm(`即将删除该用户所有信息，该操作不可逆！`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        userApi.deleteUser(row.id).then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.getUsers();
        });
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
  }

  deleteUserRoles(row: IUserDetail) {
    this.$confirm(`此操作将删除用户所有角色 ${row.name}?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        userApi.deleteUserRoles(row.id).then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.getUsers();
        });
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
  }

  doAction() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$refs.createModifyRef.validate((valid: boolean) => {
      if (!valid) {
        this.$message.warning("请检查表单");
        return;
      }
      this.createModifyLoad = true;
      let func = userApi.createUser;
      // eslint-disable-next-line no-prototype-builtins
      if (this.createModifyParams.hasOwnProperty("id")) {
        // @ts-ignore
        func = userApi.modifyUser;
      }
      func(this.createModifyParams)
        .then(() => {
          this.$message.success(this.title);
          this.visible = false;
          this.getUsers();
        })
        .catch((e) => {
          this.$message.warning(`${this.title}, ${e}`);
        })
        .finally(() => {
          this.createModifyLoad = false;
        });
    });
  }

  doAddUserAction() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (this.roleAddUserParams.roleIds.length > 0) {
      this.createModifyLoad = true;
      roleApi
        .roleAddUser(this.roleAddUserParams)
        .then(() => {
          this.$message.success(this.title);
          this.addRolevisible = false;
          setTimeout(() => {
            this.getUsers();
          }, 500);
        })
        .catch((e) => {
          this.$message.warning(`${this.title}, ${e}`);
        })
        .finally(() => {
          this.createModifyLoad = false;
          this.roleAddUserParams = {
            isSingleUser: false,
            userIds: [],
            roleIds: [],
          };
        });
    } else {
      this.$message.error("请选择角色");
    }
  }

  getRoleIds(roles: any) {
    const result: Array<number> = [];
    for (const role in roles) {
      // @ts-ignore
      result.push(role.id);
    }
    return result;
  }

  getRoles(query: string) {
    roleApi
      .getRoles({
        name: query,
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
      } as IRoleParams)
      .then((data) => {
        this.roles = data;
      })
      .catch((e) => {
        // this.$message.warning(`获取角色列表失败, ${e}`);
      });
  }

  getDepartments(query: string) {
    departmentApi
      .getDepartments({
        name: query,
        perPage: 99999,
        baseOnly: 1,
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
      } as IDepartmentParams)
      .then((data) => {
        this.departments = data;
      })
      .catch((e) => {
        // this.$message.warning(`获取科室列表失败, ${e}`);
      });
  }

  getEnterprises(query: string) {
    enterpriseApi
      .getEnterprises({
        name: query,
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
      } as IGetEnterprisesParam)
      .then((data) => {
        this.enterprises = data;
      })
      .catch((e) => {
        // this.$message.warning(`获取企业列表失败, ${e}`);
      });
  }

  formatStatus(status: number) {
    let result = "null";
    this.statuss.map((item) => {
      if (status === item.value) {
        result = item.label;
      }
    });
    return result;
  }
}
