
































































































































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { ICreateInstrumentParams, IInstrumentParams, instrumentApi } from '@/api/instrument'
import { UserModule } from '@/store/modules/user'
import { IUserDetail, IUserParams, userApi } from '@/api/user'
import InstrumentCreateForm from '@/components/InstrumentCreateForm.vue'
import InstrumentSelect from '@/components/Select/InstrumentSelect.vue'
import PERMISSION from '@/constants/permission'

@Component({
  components: {
    InstrumentCreateForm,
    InstrumentSelect
  }
})
export default class PeiTao extends Vue {
  @Prop() data: any
  @Prop({ default: [] }) fields!: any[]
  @Ref('create-instrument-form') createInstrument!: InstrumentCreateForm
  visible = false
  createLoading = false
  instruments: any = []
  users: any = {}
  condition: any = {}
  loading: boolean = false
  params = {
    page: 1,
    perPage: 15,
    parentId: 0
  }
  historyVisible = false
  history: any = {}
  allUsers = [] as IUserDetail[]

  addVisible = false
  createInsForm = {
    name: '',
    code: '',
    extraFields: {},
  } as ICreateInstrumentParams

  rules = {
    name: [{
      required: true,
      message: '请输入名称',
      trigger: 'blur'
    }],
    code: [{
      required: true,
      message: '请输入编码',
      trigger: 'blur'
    }],
    model: [{
      required: true,
      message: '请输入型号',
      trigger: 'blur'
    }],
    brand: [{
      required: true,
      message: '请输入品牌',
      trigger: 'blur'
    }],
    buyDate: [{
      required: true,
      message: '请选择购入时间',
      trigger: 'blur'
    }],
  }

  combVisible = false
  combLoading = false
  childId = null

  get selectCondition() {
    let con = {} as { [key: string]: any }
    con['hasChild'] = 0
    for (const field of this.fields) {
      if (field.hasOwnProperty('name') && field.name === '资产性质') {
        con['extraId'] = field.id
        con['extraValue'] = '配套'
        return con
      }
    }
    return con
  }

  comComponent() {
    if (!this.childId) {
      this.$message.warning('请选择需要关联的设备')
      return
    }
    if (this.childId === Number(this.$route.params.id)) {
      this.$message.warning('不能关联自己')
      return
    }
    this.combLoading = true
    instrumentApi.getInstrumentDetail(this.childId).then((data: any) => {
      if (data.parentId) {
        this.$confirm(`选择的设备已经关联其他设备, 确定关联至当前设备?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.doComComponent()
        })
      } else {
        this.doComComponent()
      }
    })
  }

  doComComponent() {
    // @ts-ignore
    instrumentApi.modifyInstrument({
      // @ts-ignore
      id: this.childId,
      // @ts-ignore
      parentId: Number(this.$route.params.id)
    }).then(() => {
      this.$message.success('关联成功')
      this.getSubIns()
    }).catch((e) => {
      this.$message.warning(`关联失败, ${e}`)
    }).finally(() => {
      this.combLoading = false
      this.combVisible = false
    })
  }

  async createComponent() {
    if (await this.createInstrument.validate()) {
      this.createLoading = true
      instrumentApi.createInstrument(this.createInsForm)
        .then(() => {
          this.$message.success('新增成功')
          this.addVisible = false
          this.getSubIns()
        })
        .catch((e) => {
          this.$message.warning(`新增失败, ${e}`)
        })
        .finally(() => {
          this.createLoading = false
        })
    } else {
      this.$message.warning('请检查表单')
    }
  }

  insDetailUrl(insId: number) {
    return window.location.origin + `/#/ins/detail/${insId}`
  }

  get PERMISSION() {
    return PERMISSION
  }

  get config() {
    return UserModule.config
  }

  get statusIcon() {
    // @ts-ignore
    return (row) => {
      // @ts-ignore
      if (!this.config.noUseStatus) {
        return -2
      }
      // @ts-ignore
      if (this.config?.noUseStatus?.includes(row.status)) {
        return -1
      }
      if (row.inTentative) return 1
      return 0
    }
  }

  get statusOptions() {
    return (
      // @ts-ignore
      this.config?.instrumentStatus?.map((obj) => {
        const key = Number(Object.keys(obj)[0])
        return {
          key,
          value: key,
          label: obj[key],
        }
      }) || []
    )
  }

  get formatterLabel() {
    // @ts-ignore
    return (row) => {
      // @ts-ignore
      if (!this.config.noUseStatus) {
        return ''
      }
      const statusLabel = this.statusOptions?.find(
        // @ts-ignore
        ({ key }) => key === row.status
      )?.label
      // @ts-ignore
      if (this.config?.noUseStatus?.includes(row.status)) {
        return `停用(${statusLabel})`
      }
      // @ts-ignore
      return `${this.config?.in_tentative?.[row.inTentative]}(${statusLabel}）`
    }
  }

  getAllUsers() {
    userApi.getUsers({
      page: 1,
      perPage: 99999,
      baseOnly: 1,
    } as IUserParams).then((data) => {
      this.allUsers = data.items
    })
  }

  onOpenHistory() {
    this.historyVisible = true
    if (this.allUsers.length == 0) {
      this.getAllUsers()
    }
    this.getHistory()
  }

  getOperateUser(userId: number | null) {
    if (!userId) {
      return null
    }
    return this.allUsers.find(item => {
      return item.id === userId
    })
  }

  async getHistory() {
    this.history = await instrumentApi.history(this.data.id)
  }

  private getUsers(query: string) {
    userApi
      .getUsers({
        name: query,
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
      } as IUserParams)
      .then((data) => {
        this.users = data
      })
      .catch((e) => {
        this.$message.warning(`获取用户列表失败, ${e}`)
      })
  }

  private getSubIns() {
    this.loading = true
    instrumentApi.getSubInstruments(this.$route.params.id).then((data: any) => {
      this.instruments = data.peitao
      this.loading = false
    }).catch(e => {
      this.$message.warning(`获取配套失败, ${e}`)
      this.loading = false
    })
  }

  created() {
    this.getUsers('')
    this.getSubIns()
  }
}
