






















































































import { Component, Vue, Prop } from "vue-property-decorator";
import { instrumentApi } from "@/api/instrument";
import PERMISSION from "@/constants/permission";
import { fileApi } from '@/api/files'
import { convertRes2Blob } from '@/utils/download'

@Component
export default class InstrumentFile extends Vue {
  @Prop({required: true}) id!: number;
  params = {page: 1, perPage: 15} as {page: number, perPage: number}
  instrumentFiles = {} as {page: number, perPage: number, total: number, items: any[]}
  loading = false;
  uploadShow = false
  fileList = [] as {name: string, url: string, path: string}[]
  file = {} as any
  uploadLoading = false

  async downLoadFile(row: any) {
    const response = await fileApi.download(row.path);
    convertRes2Blob(response, row.name);
  }

  get PERMISSION() {
    return PERMISSION;
  }

  showUpload() {
    this.uploadShow = true
    this.fileList = []
    this.file = {}
  }

  handleSuccess(res: any, file: any, fileList: any) {
    if (!res.success) {
      this.$message.error(`上传图片失败, ${res.message}`)
      return
    }
    file.path = res.data
    this.file = file
    this.$forceUpdate()
  }

  get uploadUrl() {
    return process.env.VUE_APP_BASE_API + '/file/upload'
  }

  beforeRemove(file: any, fileList: any[]) {
    return this.$confirm(`确定移除 ${ file.name }？`);
  }

  handleExceed(file: any, fileList: any[]) {
    this.$message.warning("上传失败");
  }

  jumpOrder(title: string) {
    let path = ""
    if (title.startsWith("新增-")) {
      path = "create"
    } else if (title.startsWith("验收-")) {
      path = "check"
    } else if (title.startsWith("调整-")) {
      path = "adjust"
    } else if (title.startsWith("维修-")) {
      path = "fix"
    } else if (title.startsWith("处置-")) {
      path = "scrap"
    } else if (title.startsWith("检定校准-")) {
      path = "verity"
    } else if (title.startsWith("期间核查-")) {
      path = "rangecheck"
    }
    this.$router.push('/' + path)
  }

  doRelate() {
    this.uploadLoading = true
    instrumentApi.addRelateFile(this.id, this.file.name, this.file.path)
      .then((data) => {
        console.log(data)
        this.$message.success("关联成功")
        this.uploadShow = false
        this.getRelateFiles()
      }).catch((e) => {
      this.$message.warning(`关联失败, ${e}`)
    }).finally(() => {
      this.uploadLoading = false
    })
  }

  private getRelateFiles() {
    this.loading = true;
      instrumentApi.getRelateFiles(
        this.id, this.params.page, this.params.perPage
      ).then((data) => {
        // @ts-ignore
        this.instrumentFiles = data
      }).catch((e) => {
        this.$message.warning(`获取文件列表失败, ${e}`)
      }).finally(() => {
        this.loading = false
      })
  }
  created() {
    this.getRelateFiles();
  }

  unrelate(row: any) {
    this.$confirm(`确定删除 ${ row.name }？`).then(() => {
      instrumentApi.deleteRelateFile(row.id).then(() => {
        this.$message.success("删除成功")
        this.getRelateFiles()
      }).catch((e) => {
        this.$message.warning(`删除失败, ${e}`)
      })
    })
  }
}
