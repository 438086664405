

















import { Component, Vue, Prop } from "vue-property-decorator";
import { fileApi } from "@/api/files";
import { convertRes2Blob } from "@/utils/download";

@Component
export default class Record extends Vue {
  @Prop() data: { files: any; } | undefined;

  get files() {
    // @ts-ignore
    return this.data.files;
  }

  async onView(file: any) {
    if (file.type === "url") {
      const url =
        file.url.indexOf("http") > -1 ? file.url : `http://${file.url}`;
      window.open(url);
    } else {
      const response = await fileApi.download(file.url);
      convertRes2Blob(response, file.name);
    }
  }
}
